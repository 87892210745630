<template>
    <div class="vx-col md:w-full sm:w-auto">
        <vx-tooltip class="position-icon" :text="$t('button.off_on')" position="top">
            <vs-switch color="success" class="mr-4 mt-3" v-model="turnOnOff"></vs-switch>
        </vx-tooltip>

        <vx-tooltip :text="$t('button.historyInOut')" position="top">
            <feather-icon icon="ActivityIcon" class="action-success mr-4"
                          svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="history" />
        </vx-tooltip>

        <vx-tooltip :text="$t('button.edit')" position="top" v-if="this.status == true">
            <feather-icon icon="EditIcon" class="action-success mr-4"
                svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="editRecord" />
        </vx-tooltip>

        <vx-tooltip :text="$t('button.delete')" position="top">
            <feather-icon icon="TrashIcon" class="action-err " svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                @click="confirmDeleteRecord" />
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    data() {
        return {
            status: true,
        }
    },

    computed: {
        turnOnOff: {
            get() {
                return this.status;
            },
            set() {
                if (this.status) {
                    this.turnOffService()
                } else {
                    this.turnOnService()
                }
            }
        }
    },
    created() {
        this.status = this.params.data.status
    },

    methods: {
        history() {
            this.$router.push(`/user/in-out-history-customer?id=${this.params.data.id}&type=${this.params.data.type}`).catch(() => {
            })
        },
        editRecord() {
            this.$router.push(`/user/edit-customer?id=${this.params.data.id}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('warningMenuCustomer.titleWarning'),
                text: this.$t('warningMenuCustomer.textDeleteCustomer') + `"${this.params.data.fullName}"?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/customer/delete/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_VIP_CUSTOMER', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: this.$t('warningMenuCustomer.deleteSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        turnOnService() {
            this.$vs.loading()
            this.$crm.post(`/customer/enable-customer/${this.params.data.id}`).then(() => {
                this.$vs.loading.close()
                this.status = true
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        turnOffService() {
            this.$vs.loading()
            this.$crm.post(`/customer/disable-customer/${this.params.data.id}`).then(() => {
                this.$vs.loading.close()
                this.status = false
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        }

    }
}
</script>
