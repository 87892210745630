<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-row items-center mb-2">
                <div class="vx-col md:w-1/2">
                    <TitleScreen></TitleScreen>
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-button
                        class="float-right"
                        @click="addCustomer"
                        icon="icon-plus"
                        icon-pack="feather"
                        color="primary">
                        {{$t('menuCustomer.addCustomer')}}
                    </vs-button>
                </div>
            </div>
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/5">
                    <!-- <label class="vs-input--label">{{ $t('cardCustomer.customerType') }}</label>
                    <v-select v-model="customerTypeModel" :options="customerTypes"></v-select> -->
                </div>
                <div class="vx-col md:w-4/5 mt-6">
                    <vs-input class="float-right md:w-1/4"
                              v-model="searchQuery"
                              @input="updateSearchQuery" :placeholder="$t('button.search')"/>
                </div>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="customerData"
                         :animateRows="true"
                         :pagination="true"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <!-- ITEMS PER PAGE -->
            <!-- <Pagination
                :itemList="customerData"
                :currentPage="currentPage"
                :paginationPageSize="paginationPageSize"
                :gridApi="gridApi"
            /> -->

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"
                @change="loadPageCustomer"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import moment from "moment";
import Pagination from "../../ui-elements/Pagination.vue"
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
        Pagination,
        TitleScreen
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuCustomer.customerCode'),
                    field: 'customerCode',
                    width: 100
                },
                {
                    headerName: this.$t('menuCustomer.customerName'),
                    field: 'fullName',
                    width: 200
                },
                {
                    headerName: this.$t('menuCustomer.cardNumber'),
                    field: 'cardNumber',
                    width: 100
                },
                {
                    headerName: this.$t('menuCustomer.phoneNumber'),
                    field: 'phone',
                    width: 150,
                }, {
                    headerName: this.$t('menuCustomer.dateCreated'),
                    field: 'created_at_display',
                    width: 150,
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            customerData: [],
            // customerTypes: [
            //     {value: null, label: this.$t('menuCustomer.all'), valueNumber: null},
            //     { value: "customer", label: this.$t('cardCustomer.customer'), valueNumber: 0 },
            //     { value: "guest", label: this.$t('cardCustomer.passers'), valueNumber: 1 },
            // ],
            pageNumber: 0,
            pageSize: 20,
            totalPages: 0,
            currentPage: 1,
            listCards: [],
        }
    },
    async created() {
        await this.getDataCard()
        this.initScreen()
        this.$eventBus.$on('i18n', (event) => {
            this.columnDefs[0].headerName = this.$t('menuCustomer.customerCode');
            this.columnDefs[1].headerName = this.$t('menuCustomer.customerName');
            this.columnDefs[2].headerName = this.$t('menuCustomer.phoneNumber');
            this.columnDefs[3].headerName = this.$t('menuCustomer.dateCreated');
            this.columnDefs[4].headerName = this.$t('button.actions');
            this.gridOptions.api.setColumnDefs(this.columnDefs);
        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateVipCustomer'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_VIP_CUSTOMER', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        // totalPages() {
        //     if (this.gridApi) return this.gridApi.paginationGetTotalPages();
        //     else return 0
        // },
        // currentPage: {
        //     get() {
        //         if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        //         else return 1
        //     },
        //     set(val) {
        //         this.gridApi.paginationGoToPage(val - 1)
        //     }
        // },
        // customerTypeModel: {
        //     get() {
        //         let object = this.customerTypes.find(el => el.valueNumber == this.customerData.type);
        //         if (!object) return;
        //         return {
        //             label: object.label,
        //             value: object.value
        //         }
        //     },
        //     set(object) {
        //         this.customerData.type = object ? object.valueNumber : null;
        //         this.initScreen()
        //     }
        // },
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addCustomer() {
            this.$router.push(`/user/add-customer`).catch(() => {
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            const payload = {
                // type: this.customerData.type,
                // status: true,
                organizationId: userInfo.organization.id,
                sort: 'id',
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }

            this.$crm.post('/customer/find-by-condition', payload).then((response) => {
                if (response.data.content) {
                    this.customerData = response.data.content.map(t => {
                        let cardInfo = this.listCards.find(el => el.id == t.cardId)
                        if (cardInfo) t.cardNumber = cardInfo.cardNumber;
                        if (!cardInfo) t.cardNumber = 'N/A';
                        t.created_at_display = moment(t.createdDate).format("DD/MM/YYYY HH:mm:ss");
                        return t;
                    })
                }

                this.totalPages = response.data.totalPages
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        loadPageCustomer: function(currentPage) {
            let pageNumber = parseInt(currentPage) ? parseInt(currentPage) - 1 : 0;
            this.pageNumber = pageNumber;
            this.initScreen()
        },

        getDataCard() {
            const payload = {
                cardStaffType: 0,
                // pageSize: this.pageSize,
                // pageNumber: this.pageNumber,
                sort: "id",
            }
            this.$vs.loading();
            this.$crm.post('/card-staff/find-by-condition', payload).then((res) => {
                this.listCards = res.data.content
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
